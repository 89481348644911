<template>
  <!-- :to="{ name: 'auth-login'}" -->
  <div v-if="!isRegisterPage">
    <b-button
      class="mx-1"
      variant="primary"
      href="/login"
    >
      <feather-icon
        icon="LockIcon"
        size="16"
        class="d-inline d-sm-none"
      />    <span class="align-middle d-none d-sm-inline">Se connecter</span>
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'LoginButton',
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },

  computed: {
    isRegisterPage() {
      return this.$route.name === 'etablissements-create'
    },
  },
}

</script>
<style lang="scss" scoped></style>
