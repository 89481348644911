import utilsService from '@/services/utils/utils.service'

const {
  isAdminEtablissement,
  isAgentEtablissement,
} = utilsService.currentUserUtils()

// const getActionByProfil = () => {
//   const isEtsProfil = isAdminEtablissement || isAgentEtablissement
//   return isEtsProfil ? 'create' : 'read'
// }

// const getROuteByRole = item => {
//   const isEtsProfil = isAdminEtablissement || isAgentEtablissement
//   const { createRouteProps } = item
//   const result = { ...item }
//   result.route = createRouteProps.name
//   result.path = createRouteProps.name

//   return isEtsProfil ? item : 'read'
// }

let forms = utilsService.getListFormulaires()
forms = forms.map((e,i) => {
  const temp = JSON.parse(JSON.stringify(e))
  temp.action = 'list'
  temp.resource = e.resource || ''

  // if(i===3) temp.action='read'
  // change route according to role
  // temp.route = getROuteByRole()

  return temp
})
// console.log(forms)
export default [
  {
    header: 'Formulaires',
    icon: 'BookIcon',
    resource: 'Public',
    action: 'read',
    children: [
      ...forms,
    ],
  },
]
