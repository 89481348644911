import utilsService from '@/services/utils/utils.service'

const { faqDetail } = utilsService.currentUserUtils()
const faqId = faqDetail && faqDetail._id || ''

export default [
  // {
  //   title: 'FAQs',
  //   icon: 'BookIcon',
  //   route: 'faq-list',
  //   route: {
  //     name: 'faq-list',
  //     params: { faqId },
  //   },
  //   action: 'read',  
  //   resource: 'Public'
  // },
  {
    title: "Manuel d'Utilisation",
    icon: 'BookIcon',
    route: 'manuel-utilisation',
    // route: {
    //   name: 'faq-list',
    //   params: { faqId },
    // },
    action: 'read',  
    resource: 'Public'
  },
]



// export default [
//   {
//     header: 'Faq',
//     icon: 'BookIcon',
//     resource: 'Public',
//     action: 'read',
//     route: {
//       name: 'faq-list',
//       params: { faqId },
//     },
//     // children: [
//     //   // {
//     //   //   title: 'Rechercher une faq',
//     //   //   icon: 'ActivityIcon',
//     //   //   route: {
//     //   //     name: 'faq',
//     //   //   },
//     //   //   action: 'read',
//     //   //   resource: 'Public',
//     //   // },

//     //   {
//     //     title: 'Listes des faq',
//     //     icon: 'ActivityIcon',
//     //     route: {
//     //       name: 'faq-list',
//     //       params: { faqId },
//     //     },
//     //     action: 'read',
//     //     resource: 'Public',
//     //   },

//     //   {
//     //     title: 'Créer une faq',
//     //     icon: 'ActivityIcon',
//     //     route: {
//     //       name: 'faq-create',
//     //     },
//     //     action: 'create',
//     //     resource: 'faq',
//     //   },

//     //   // {
//     //   //   title: 'Éditer une faq',
//     //   //   icon: 'EditIcon',
//     //   //   route: {
//     //   //     name: 'faq-edit',
//     //   //   },
//     //   //   action: 'read',
//     //   //   resource: 'Public',
//     //   // },
//     // ],
//   },

// ]
