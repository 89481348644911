export default [
  {
    header: 'Configurations',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'configurations',
    children: [
      {
        title: 'Permissions',
        route: 'permissions-index',
        icon: 'LockIcon',
      },
      {
        title: 'Secteurs',
        route: 'secteurs-index',
        icon: 'LockIcon',
      },
      // {
      //   title: 'Types Etablissements',
      //   route: 'typeetablissements-index',
      //   icon: 'LockIcon',
      // },
     
    ],
  },
]
