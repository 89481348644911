import utilsService from '@/services/utils/utils.service'
import localstorageService from '@/services/localstorage/localstorage.service'

const userId = localstorageService.getUserId()
const forms = utilsService.getListFormulaires()
export default [{
  header: 'Commissariats',
  action: 'read',
  resource: 'commissaire',
  icon: 'BookIcon',
  children: [
    {
      title: 'Liste des commissariats',
      icon: 'ListIcon',
      route: {
        name: 'commissariats-list',
      },
      action: 'read',
      resource: 'commissaire',
    },
    {
      title: 'Ajouter un commissariat',
      icon: 'PlusIcon',
      route: {
        name: 'commissariats-create',
        // params: { commissaireId: userId },
      },
      action: 'create',
      resource: 'commissaire',
    },
  ],
}]
