// import utilsService from '@/services/utils/utils.service'
// import localstorageService from '@/services/localstorage/localstorage.service'
// const userId = localstorageService.getUserId()
// const forms = utilsService.getListFormulaires()

export default [{
  title: 'Gestion des comptes',
  // action: 'read',
  // resource: 'Public',
  resource: 'utilisateur',
  action: 'administrate',
  icon: 'UserIcon',
  route: 'users-list',
}]

// export default [{
//   header: 'Gestion des Comptes',
//   resource: 'utilisateur',
//   action: 'administrate',
//   icon: 'BookIcon',
//   children: [
//     {
//       title: 'Liste des utilisateurs',
//       icon: 'UserIcon',
//       route: {
//         name: 'users-list',
//         // params: { commissaireId: userId },
//       },
//       resource: 'utilisateur',
//       action: 'administrate',
//     },
//   ],
// }]
