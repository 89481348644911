<template>
  <div class="navbar-container d-flex content align-items-center justify-content-between">
    <!-- left -->
    <div>
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-flex d-lg-flex"
      >
        <AppLogo/>
        <!-- <bookmarks /> -->
      </div>
    </div>
    <!-- center -->
    <div class="d-flex flex-row">
      <!-- <span class="mr-1 my-auto h3">
        <strong>Menu</strong>
      </span>
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <i class="las la-bars iconBig"></i>
          </b-link>
        </li>
      </ul> -->
    </div>
    <!-- Right Col -->
    <div>
      <!-- <div class="d-flex flex-row">
        <div class="mr-1 my-auto">Choirsir un secteur </div>
        <v-select
          v-model="selectedSector"
          :options="sectorsCustom"
          label="nom"
          class="mr-1" style="width:400px;"
          placeholder="Vous pouvez choisir un secteur d'activités"
        />
      </div> -->
      <b-navbar-nav class="nav align-items-center ml-auto">
        
        <!-- <locale /> -->
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        <!-- <search-bar /> -->
        <!-- <cart-dropdown /> -->
        <!-- <notification-dropdown v-if="isAuthenticated"/> -->
        <RegisterButton v-if="!isAuthenticated"/>
        <login-button v-if="!isAuthenticated" />
        <user-dropdown v-if="isAuthenticated" />
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import RegisterButton from '@/components/RegisterButton.vue'
import LoginButton from '@/components/LoginButton.vue'
import AppLogo from '@/components/AppLogo.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import vSelect from 'vue-select'
import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    LoginButton,
    RegisterButton,
    AppLogo,
    vSelect,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
    // ...mapState('params', {
    //   currentSectorSelected: 'currentSectorSelected',
    // }),
    // ...mapGetters('params', {
    //   getter_currentSectorSelected: 'getter_currentSectorSelected'
    // }),
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   async handler(val, old) {
    //     // await this.action_fetchTypesRoles()
    //     this.loadData()
    //   },
    // },
  },
  data(){
    return{
      selectedSector: null,
      sectors: null,
      sectorsCustom: [],
      sectorsTotal: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
    }
  },
  methods: {
    // ...mapMutations('params', {
    //   setter_currentSectorSelected: 'setter_currentSectorSelected'
    // }),
    // ...mapActions('secteurs', {
    //   fetchSecteurs: 'fetchSecteurs',
    //   addSecteurs: 'addSecteurs',
    // }),
  },
}
</script>
